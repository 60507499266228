import { createContext, useContext, useEffect, useState } from "react";
import type { IWebApp } from "./telegram.interface";

export interface TelegramContextProps {
  tmaData: IWebApp | null;
  currentUser: UserAuthData | null;
  setUserAuth: (user: UserAuthData) => void;
  clearUserLogout: () => void;
}

export interface UserAuthData {
  firstName: string | null;
  lastName: string | null;
  telegramId: string | null;
  userName: string | null;
}


export const TelegramContext = createContext({} as TelegramContextProps);
export const useAuthData = () => useContext(TelegramContext);

export const TelegramProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [tmaData, setTmaData] = useState<IWebApp | null>(null);
  const [currentUser, setCurrentUser] = useState<UserAuthData | null>(null);

  useEffect(() => {
    const id = sessionStorage.getItem('tgId');
    const app = (window as any).Telegram?.WebApp;

    if (!!app?.initDataUnsafe?.user?.id) {
      app.ready();
      setTmaData(app);
      setCurrentUser({
        firstName: app?.initDataUnsafe.user?.first_name, 
        lastName: app?.initDataUnsafe.user?.last_name, 
        telegramId: app?.initDataUnsafe.user?.id, 
        userName: app?.initDataUnsafe.user?.username})
    } else if (!!id && id?.length > 1) {
      setCurrentUser({
        firstName:  sessionStorage.getItem('tgFirstName'), 
        lastName:  sessionStorage.getItem('tgLastName'),  
        telegramId: sessionStorage.getItem('tgId'), 
        userName: sessionStorage.getItem('tgUsername'), 
      })
    } else {
      setTmaData(null)
    }

  }, []);

  
    const setUserAuth = (user: UserAuthData) => {
      setCurrentUser(user);
  
      if(user.telegramId) {
        sessionStorage.setItem('tgFirstName', user?.firstName ? user?.firstName : "" );
        sessionStorage.setItem('tgLastName', user.lastName ? user.lastName : "");
        sessionStorage.setItem('tgId', user.telegramId ?`${user.telegramId}` : "");
        sessionStorage.setItem('tgUsername', user.userName ? user.userName : '');
      }
  
    }
  
  
    const clearUserLogout = () => {
      sessionStorage.clear();
      setCurrentUser({
        firstName: null, 
        lastName: null,
        telegramId: null,
        userName: null
      })
  }
  

  return (
    <TelegramContext.Provider value={{
      currentUser,
      setUserAuth,
      clearUserLogout,
      tmaData,
    }}>
      <script
        src="https://telegram.org/js/telegram-web-app.js?56"
      />      {children}
    </TelegramContext.Provider>
  );
};

export const useTelegram = () => useContext(TelegramContext);