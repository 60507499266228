import { Link } from '@mui/material';
import { baseApi } from 'api';
import  { useState, useEffect } from 'react';
import QRCode from 'react-qr-code';
import styles from '../auth.module.scss';
import { v4 as uuidv4 } from 'uuid';
import { useIsMobileSize } from 'shared';
import { TgButton } from 'components';
import { useTelegram } from 'context/telegram/TgProvider';

const LongPollingAuth = (props:any) => {
    const { handleBack } = props;

    const [id, setId] = useState<string | null>(null); 
    const [intervalId, setIntervalId] = useState<NodeJS.Timer | null>(null);
    const { setUserAuth } = useTelegram();

     const { isSizeMobile } = useIsMobileSize();

    const getIsAuth = async () => {

        if (id && id?.length > 20) {
            const response = await baseApi.authQr(id);
            if(intervalId && response.data.authorized === true) {
                setUserAuth({
                    firstName: response?.data?.firstName || null, 
                    lastName: response?.data?.lastName || null, 
                    telegramId: response?.data?.telegramId, 
                    userName: response?.data?.userName || null
                });
                clearInterval(intervalId);
                localStorage.removeItem('queryCode');
                handleBack();
            } else if(response.status === 406) {
                localStorage.removeItem('queryCode');
                handleBack();
            }
        }
       
    };
    const newTokenCheck = async (queryCode:string) => {
        const respToken = await baseApi.checkToken(queryCode);

        console.log('respToken', respToken)

        if(respToken === 'ok') {
            localStorage.setItem('queryCode', queryCode);
            setId(queryCode);
            getIsAuth();
        }
    }

  useEffect(() => {
    const codeId = localStorage.getItem('queryCode');
    if (codeId && codeId.length > 30) {
        setId(codeId);
    } else {
        const newCode =  uuidv4();
        newTokenCheck(newCode)
        
    };

    const idTime = setInterval(getIsAuth, 2000);
    setIntervalId(idTime);

    return () => {
      clearInterval(idTime); 
    };
  }, [id]);

  const openBot = () => {
    window.open(`https://t.me/${process.env.REACT_APP_TG_NAME_BOT}?start=${id}`, '_blank', 'noopener,noreferrer');
  }

  return (
    <div className={styles.qrCode} >

        {
            isSizeMobile ? 
                <Link href={`https://t.me/${process.env.REACT_APP_TG_NAME_BOT}?start=${id}`} target="_blank" sx={{width: '100%'}}>
                    <TgButton styled={{fontSize: '12px', width: '100%'}} title='Войти через Telegram-bot'/>
                </Link>
            :
            <>
                {
                    id && 
                
                    <QRCode
                      size={256}
                      style={{ height: "auto", maxWidth: "248px", width: "100%" }}
                      value={`https://t.me/${process.env.REACT_APP_TG_NAME_BOT}?start=${id}`}
                      viewBox={`0 0 256 256`}
                    />
                }
                <p className={styles.description}>
                    Отсканируйте QR-код c помощью камеры телефона 
                    или перейдите по ссылке на &nbsp;
                    <span className={styles.spanLinkBot} onClick={openBot}>
                        Telegram-bot
                    </span>
                </p>
            </>
        }
    </div>
  )
};

export default LongPollingAuth;