import { Box, Link, Typography} from '@mui/material';
import {  TgButton, TrustPilot } from 'components';
import styles from './history.module.scss';
import { EmptyHistory } from './emptyHistory';
import React, { useEffect, useState } from 'react';
import { baseApi } from 'api';
import { ListHistory } from './listHistory/ListHistory';
import { HistoryTransferItem, ListStatusHistory } from './history.interface';
import { CardHistory } from './cardHistory';
import clsx from 'clsx';
import { useIsMobileSize } from 'shared';
import { useTelegram } from 'context/telegram/TgProvider';

export const History = () => {
    const [listHistory, setListHistory] = useState<ListStatusHistory>({
        all: 0,
        cancelled: 0,
        errored: 0,
        inProgress: 0,
        successed: 0, 
    });
    
    const [active, setActive] = useState('all');
    const [dataTransfer, setDataTransfer] = useState<HistoryTransferItem[] | null>(null);

    const { currentUser } = useTelegram();


    const { isSizeMobile } = useIsMobileSize()

    useEffect(()=> {
        (async()=>{
            if(currentUser?.telegramId) {
                const transferQuery = `?telegramId=${currentUser?.telegramId}${active !== 'all' ? `&status=${active !== 'cancelled' ? active : 'errored'}`: ''}`;

                const responseTransfer = await baseApi.getHistoryTransfer(transferQuery);

                setDataTransfer(responseTransfer)
            } else {
                setDataTransfer(null)
            }
        })()
    },[active]);

    useEffect(()=> {
        (async()=>{
            if(currentUser?.telegramId) {
                const countList = await baseApi.getHistoryList(+currentUser?.telegramId);
                setListHistory(countList);
            } else {
                setListHistory({
                    all: 0,
                    cancelled: 0,
                    errored: 0,
                    inProgress: 0,
                    successed: 0, 
                })
            }
        })()
    },[])

    return (
        <Box className={styles.historyWrapper}>
            <Box className={styles.container}>
                <Typography variant='body1'>
                    ИСТОРИЯ
                </Typography>

                <Box className={styles.contentHistory}>
                    <Box className={styles.historyItemsContent}>

                    {
                        !!listHistory.all
                        ?
                            <>
                                <ListHistory data={listHistory} active={active} setActive={setActive} />
                                <Box className={clsx(styles.arrayTransfer,  !!dataTransfer && dataTransfer?.length <= 2 && styles.heightList)}>
                                {
                                    !!dataTransfer && dataTransfer.map((el)=> (
                                        <React.Fragment key={el.number}>
                                            <CardHistory  item={el} itemLength={dataTransfer.length} />
                                        </React.Fragment>
                                    ))
                                }
                                
                                </Box>
                            </>
                        :
                            <EmptyHistory />
                            
                    }
                        </Box>
                    
                    <Box className={styles.trustPilotWrapper}>
                        {!!listHistory.all && 
                            <Box>
                                <Typography className={styles.askRequest}>
                                    Указана предварительная сумму к получению. <br/> Точная сумма будет доступна в созданной чат-<br/>кассе по заявке.
                                </Typography>
                                <Link href={'https://t.me/emposupport'} sx={{width: '100%'}}>
                                    <TgButton title='Задать вопрос' styled={{marginTop: '24px', marginBottom: isSizeMobile ?'0':'36px'}} />
                                </Link>
                            </Box>
                        }
                        <TrustPilot hidden={!!dataTransfer && isSizeMobile} styled={styles.trustPilot} />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
