import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { Layout } from './layout';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { theme } from './theme/theme';
import { About, ExchangeRules, Faq, History, Main, NotFound } from 'pages';
import { TelegramProvider } from 'context/telegram/TgProvider';

function App() {
  return (

    <ThemeProvider theme={theme}>
        <TelegramProvider>
        <CssBaseline />
          <BrowserRouter>
            <Routes >
              <Route element={<Layout/>}>
                  <Route path="/" element={<Main />} />
                  <Route path="/history" element={<History />} />
                  <Route path="/FAQ" element={<Faq/>} />
                  <Route path="/exchange-rules" element={<ExchangeRules />} />
                  <Route path="/support" element={<>support.//....</>} />
                  <Route path="/about" element={<About/>} />
                  <Route path="*" element={<NotFound />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </TelegramProvider>
    </ThemeProvider>

  );
}

export default App;
