import { useEffect } from 'react';
import styles from './auth.module.scss'
import { Box, Button, Divider, Typography } from '@mui/material';
import {ReactComponent as LogoSmall} from 'assets/icon/logoSmall.svg'
import LongPollingAuth from './longPollingAuth/LongPollingAuth';
import { useTelegram } from 'context/telegram/TgProvider';

export interface UserApiTg {
    first_name: string;
    last_name: string;
    id: string;
    username: string;
    photo_url: string;
}
export interface ApiTelegramResponse {
    user: UserApiTg;
}

export const Auth = (props: any) => {
    const { 
        handleNext, 
        // handleOpenSuccess, 
        handleBack, 
        handleSubmit, 
        onSubmit, 
        handleBackTitle='НАЗАД',
        // setCloseAuth,
    } = props;
    

    
        // const handleSubmitData = async() => {
        //     handleSubmit(onSubmit)();
        // };

    // const nameBot = process.env.REACT_APP_TG_NAME_BOT;
    // const widgetTgLink = process.env.REACT_APP_TG_WIDGET;

    // const { setUserAuth } = useTelegram();

    // useEffect(() => {
    //     const button = document.createElement('script');
    //     button.async = true;
    //     button.src = widgetTgLink || 'https://telegram.org/js/telegram-widget.js?22';
    //     button.setAttribute('data-telegram-login', nameBot || 'empoaibot');
    //     button.setAttribute('data-size', 'large');
    //     button.setAttribute('data-radius', '10');
    //     button.setAttribute('data-request-access', 'write');
    //     button.setAttribute('data-onauth', 'onTelegramAuth(user)');
        
    //     const parent = document.getElementById('telegram-widget');
    //     parent?.appendChild(button)


    //     const handleSubmitData = () => {
    //         handleSubmit(onSubmit)();
    //     };

    //     window.onTelegramAuth = (user) => {
    //         setUserAuth({
    //             firstName: user.first_name,
    //             lastName: user.last_name,
    //             telegramId: user.id,
    //             userName: user.username,
    //         })

    //         !!handleNext && handleNext();
    //         !!handleOpenSuccess && handleOpenSuccess();
    //         !!handleSubmit && handleSubmitData()
    //         !!setCloseAuth && setCloseAuth(false)
    //     }
    //     return () => {
    //         parent?.removeChild(button);
    //     }
    // }, []);

    return (
        <Box className={styles.telegramWidget} >
            <Box className={styles.cardContent}>
                <LogoSmall />
                <Typography className={styles.cardTitle} variant='body1'>
                    Чтобы продолжить, авторизуйтесь через Telegram
                </Typography>
        
                <Typography className={styles.cardDescription} variant='subtitle2'>
                    Вы сможете получить уведомления о актуальном курсе и информацию о следующих шагах
                </Typography>
{/* 
                <Box className={styles.buttons} >
                    <Box id="telegram-widget"/>
                </Box>  */}

                <Box className={styles.qrAuth}>
                    {/* <Divider sx={{height: '3px', width: '100%', borderColor: '#000'}} />

                    <Typography className={styles.qrDescription} variant='subtitle2'>
                        Или через нашего телеграм бота
                    </Typography> */}

                    <LongPollingAuth handleBack={handleBack} />

                </Box>
                {
                    handleBack && <Button variant='contained' color='secondary' onClick={handleBack} className={styles.buttonBack}>
                        {handleBackTitle}
                    </Button>
                }
            </Box>
        </Box>
    )
}